import React from 'react'
import { FaFacebookSquare, FaInstagramSquare, FaYoutubeSquare } from 'react-icons/fa'
import "./Navbar.css"
import { NavLink } from 'react-router-dom';

//import { GiHamburgerMenu } from "react-icons/gi";


const Navbar = () => {
  return (
    <>
      <nav className="main-nav">
        {/*  1st logo part */}
        <div className="logo">
            <h2>
                <span>S</span>hubh
                <span>J</span>eet
            </h2>
        </div>

        {/*  2nd menu part */}
        <nav className="menu-link">
            <ul>
                <li>
                    <NavLink to="/">Home</NavLink>
                </li>
                <li>
                    <NavLink to="/about">About Us</NavLink>
                </li>
                <li>
                    <NavLink to="/services">Services</NavLink>
                </li>
                <li>
                    <NavLink to="/contact">Contact</NavLink>
                </li>
            </ul>
        </nav>

        {/*  3rd social media link */}
        <div className="social-media">
            <ul className="social-media-desktop">
                <li>
                    <a href="https://m.facebook.com/" 
                       target="_th">
                        <FaFacebookSquare className="facebook"/>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/p/C5WHHxztFva/?igsh=c2Z5c2I4aDNiYW9m" 
                       target="_th">
                        <FaInstagramSquare className="instagram"/>
                    </a>
                </li>
                <li>
                    <a href="https://youtu.be/KKr91v7yLcM?si=fqQjZcRgI-FI2V6V" 
                       target="_th">
                        <FaYoutubeSquare className="youtube"/>
                    </a>
                </li>
            </ul>
        </div>
      </nav> 

      


    </>

  
// //{ /*hero section */}
// <section className="hero-section">
// <p>Welcome to</p>
// <h1>Gali Gali me shoor hai biduu ka hi joor hai.</h1>
// </section>


  )
}

export default Navbar
