import React from 'react'
import Navbar from './Navbar'
import { Card,  Col, Container, Row } from 'react-bootstrap'
import IITJEE from '../images/IITJEE.jpg';
import GATE from '../images/GATE.jpg';
import Boards from '../images/Boards.jpg'
import SoftwareDevelopment from '../images/SoftwareDevelopment.jpg'
import Engineering from '../images/Engineering.jpg'
import AutoCAD from '../images/AutoCAD.jpg'


const Services = () => {
  return (
    <>
    <Navbar></Navbar>
    <Container fluid >
        <Row style={{ margin: '10px' }}>
          <Col sm={4}>
          <Card style={{  border: '1px solid green', background: '#f2f5f3', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Card.Img src={IITJEE} style={{ width: '200px', height: '200px', borderRadius: '50%', objectFit: 'cover', marginTop: '20px', border: '1px solid #008080' }} className="mx-auto"/>
          </Card>
          </Col>
          <Col sm={4}>
          <Card style={{  border: '1px solid green', background: '#f2f5f3', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Card.Img src={GATE} style={{ width: '200px', height: '200px', borderRadius: '50%', objectFit: 'cover', marginTop: '20px', border: '1px solid #008080' }} className="mx-auto"/>
          </Card>
          </Col>
          <Col sm={4}>
          <Card style={{  border: '1px solid green', background: '#f2f5f3', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Card.Img src={Boards} style={{ width: '200px', height: '200px', borderRadius: '50%', objectFit: 'cover', marginTop: '20px', border: '1px solid #008080' }} className="mx-auto"/>
          </Card>
          </Col>
        </Row>
        
        <Row style={{ margin: '10px' }}>
          <Col sm={4}>
          <Card style={{  border: '1px solid green', background: '#f2f5f3', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Card.Img src={SoftwareDevelopment} style={{ width: '200px', height: '200px', borderRadius: '50%', objectFit: 'cover', marginTop: '20px', border: '1px solid #008080' }} className="mx-auto"/>
          </Card>
          </Col>
          <Col sm={4}>
          <Card style={{  border: '1px solid green', background: '#f2f5f3', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Card.Img src={Engineering} style={{ width: '200px', height: '200px', borderRadius: '50%', objectFit: 'cover', marginTop: '20px', border: '1px solid #008080' }} className="mx-auto"/>
          </Card>
          </Col>
          <Col sm={4}>
          <Card style={{  border: '1px solid green', background: '#f2f5f3', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Card.Img src={AutoCAD} style={{ width: '200px', height: '200px', borderRadius: '50%', objectFit: 'cover', marginTop: '20px', border: '1px solid #008080' }} className="mx-auto"/>
          </Card>
          </Col>
        </Row>
  
    </Container>
    </>
  )
}

export default Services
