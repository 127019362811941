import React from 'react'
import Navbar from './Navbar'
import { Card, CardSubtitle, CardTitle, Col, Container, Row } from 'react-bootstrap'
import Jeetu from '../images/Jeetu.jpeg'; 
import ThinkingKid from '../images/ThinkingKid.jpg'


const About = () => {
  return (
    <>
    <Navbar></Navbar>

    <Container fluid >
        <Row style={{ margin: '10px' }}>
          <Col sm={9}>
          <Card>
              <Card.Text style={{ textAlign: 'left', border: '1px solid green', background: '#f2f5f3', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              
                <h3 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#0f0f0f', fontStyle: 'italic', fontSize: '20px', lineHeight: '1.5' }}>
                <br/>The study is not for grades and marks but for relishing knowledge and how to fly without wings.
                <br/><br/>
                How to generate  intuitive Ideas and connect with subjects how to see like bird's eye.
                <br/><br/>
                How  subject talking with you real facts and enjoy the journey of subjects.
                <br/><br/>
                Books are written by human beings, not human beings written by a book so all great ideas are inside the human beings find it and enjoy it.
                <br/><br/>
                We do not teach, we teach how to think about the subjects and study by heart. 
                <br/><br/>
                <Card.Img src={ThinkingKid} style={{ width: '500px', height: '200px', borderRadius: '50%', objectFit: 'cover', marginTop: '20px', border: '1px solid #008080' }} className="mx-auto"/>
          
                </h3>
                <br/>
                <br/>
                
              </Card.Text>
          </Card>
          </Col>
          <Col sm={3}>
              <Card  style={{ textAlign: 'center',border: '1px solid green', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Card.Img src={Jeetu} style={{ width: '200px', height: '200px', borderRadius: '50%', objectFit: 'cover', marginTop: '20px' }} className="mx-auto"/>
                <Card.Body>
                  <CardTitle  style={{ fontSize: '24px', fontWeight: 'bold' }}>Jeetu Sing Bais</CardTitle>
                  <CardSubtitle style={{ fontSize: '18px' }}>Professor and Trainer</CardSubtitle>
                  <br/>
                  {/* <Card.Title style={{ fontSize: '20px', marginBottom: '10px', textAlign: 'left' }}>Skills</Card.Title> */}
                  <div style={{ background: '#f0f0f0', padding: '10px', borderRadius: '5px', textAlign: 'center' }}>
                      <ul style={{ listStyleType: 'none', paddingInlineStart: '0' }}>
                        <li><h4 style={{ color: '#0047ab' }}>Teaching/Education - Expert</h4></li>
                        <li><h4 style={{ color: '#0047ab' }}>Mentoring/Advising - Expert</h4></li>
                        <li><h4 style={{ color: '#0047ab' }}>Research/Analysis - Expert</h4></li>
                      </ul>
                  </div>
                </Card.Body>
                <Card.Text>
                <div style={{ background: '#f9f9f9', padding: '20px', borderRadius: '10px',  textAlign: 'left' }}>
                    <h3 style={{ marginBottom: '10px' }}>Introduction</h3>
                    <h4>10+ years experience in teaching and career consultation.</h4>
                    <h4>I am committed to providing personalized support and guidance to help students succeed.</h4>
                    <h4>I have a passion for helping students reach their academic goals and unlock their full potential.</h4>
                    <br></br>
                </div>
                </Card.Text>
              </Card>
          </Col>
        </Row>
  
    </Container>


    


    
    </>
  )
}

export default About
