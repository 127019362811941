import React, { useState }  from 'react'
import Navbar from './Navbar'
import { NavLink } from 'react-router-dom'; // Import NavLink from react-router-dom
import {Card, Col, Container, Row } from 'react-bootstrap'
import { Carousel } from 'react-bootstrap';
import IITJEE from '../images/IITJEE.jpg';
import GATE from '../images/GATE.jpg';
import Boards from '../images/Boards.jpg'
import SoftwareDevelopment from '../images/SoftwareDevelopment.jpg'
import RegistrationSuccess from '../images/RegistrationSuccess.png'
import Engineering from '../images/Engineering.jpg'
import AutoCAD from '../images/AutoCAD.jpg'

const Home = () => {

  const [formData, setFormData] = useState({
    fullName: '',
    mobileNo: '',
    email: '',
    selectCourse: '',
    address: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // State to track if form is submitted successfully
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track if the form is being submitted
  const [error, setError] = useState(false); // State to track if there's an error

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true); // Set submitting state to true when form is submitted
    setError(false); // Reset error state before submission
    console.log('Submitted Data:', formData);
    // Yahan par aap backend me data bhejne ka logic add kar sakte hain
    try {
      const response = await fetch('https://papi.shubhjeet.com/postApi.php/0', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: JSON.stringify({
          fullname: formData.fullName,
          mobileno: formData.mobileNo,
          email: formData.email,
          selectcourse: formData.selectCourse,
          address: formData.address
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const text = await response.text();
      console.log('Raw response text:', text);

      // Try to parse the JSON response
      const data = JSON.parse(text);
      console.log('Data sent successfully:', data);
      
      if (data.message === 'Success') {
        setIsSubmitted(true); // Update the state to show success message
      }
      else {
        setError(true); // Set error state if API response indicates failure
        alert('Something went wrong. Please Share details on Whats App : 8830222194.'); // Show alert
      }
      // Handle the API response as needed
    } 
    catch (error) {
      console.error('Error sending data:', error);
      setError(true); // Set error state if there is a network or other error
      alert('Something went wrong. Please Share details on Whats App : 8830222194.'); // Show alert
    } finally {
      setIsSubmitting(false); // Set submitting state to false after form submission is complete
    }

    
  };

  return (
    <>
    <Navbar></Navbar>

    <Container fluid style={{ borderRadius: '10px', padding: '20px' }}>
    {/* <h2 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', background: '#d1f79e', color: '#0f0f0f', fontStyle: 'italic', fontSize: '25px', lineHeight: '1.5' }}>
    Discover your potential with our innovative approach to education.
              </h2> */}

      <Row style={{ margin: '10px' }}>
        <Col sm={6} style={{ borderRadius: '10px', padding: '20px', overflow: 'hidden' }}>
        <Card style={{  border: '1px solid gray', background: '#f2f5f3', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
          
          <Carousel style={{ maxHeight: '100%', width: '100%' }}>
            <Carousel.Item style={{ maxHeight: '100%' }}>
              <img className="d-block w-100 img-fluid" src={IITJEE} alt="Second slide" style={{ borderRadius: '10px', objectFit: 'cover', maxHeight: '100%', width: '100%' }} />
              <Carousel.Caption>
                {/* <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item style={{ maxHeight: '100%' }}>
              <img className="d-block w-100 img-fluid" src={GATE} alt="Second slide" style={{ borderRadius: '10px', objectFit: 'cover', maxHeight: '100%', width: '100%' }} />
              <Carousel.Caption>
                {/* <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item style={{ maxHeight: '100%' }}>
              <img className="d-block w-100 img-fluid" src={Boards} alt="Second slide" style={{ borderRadius: '10px', objectFit: 'cover', maxHeight: '100%', width: '100%' }} />
              <Carousel.Caption>
                {/* <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item style={{ maxHeight: '100%' }}>
              <img className="d-block w-100 img-fluid" src={SoftwareDevelopment} alt="Second slide" style={{ borderRadius: '10px', objectFit: 'cover', maxHeight: '100%', width: '100%' }} />
              <Carousel.Caption>
                {/* <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item style={{ maxHeight: '100%' }}>
              <img className="d-block w-100 img-fluid" src={Engineering} alt="Second slide" style={{ borderRadius: '10px', objectFit: 'cover', maxHeight: '100%', width: '100%' }} />
              <Carousel.Caption>
                {/* <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item style={{ maxHeight: '100%' }}>
              <img className="d-block w-100 img-fluid" src={AutoCAD} alt="Second slide" style={{ borderRadius: '10px', objectFit: 'cover', maxHeight: '100%', width: '100%' }} />
              <Carousel.Caption>
                {/* <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          <br/>
            <Card.Text style={{ textAlign: 'left', border: '1px solid green', background: '#f2f5f3', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              
              <h3 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#0f0f0f', fontStyle: 'italic', fontSize: '20px', lineHeight: '1.5' }}>
              You Can Share Registration Details by Whats app also on : 8830222194.
              </h3>
              
              
            </Card.Text>
            
          </Card>
        </Col>

          
        <Col sm={6}>
        {!isSubmitted ? (
          <div style={{ border: '1px solid green', padding: '20px', borderRadius: '10px' }}>
              <div  style={{ marginBottom: '20px', padding: '20px' }}>
                 <h2 style={{ textAlign: 'center', color: 'blue', fontSize: '24px' }}>Registration Form</h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div style={{ marginBottom: '15px' }}>
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    placeholder="Enter your full name"
                    style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxSizing: 'border-box', fontSize: '18px' }}
                    onChange={handleChange}
                    required // Make full name field required
                  />
                </div>
                <div style={{ marginBottom: '15px' }}>
                  <input
                    type="tel"
                    id="mobileNo"
                    name="mobileNo"
                    placeholder="Enter your mobile number"
                    style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxSizing: 'border-box', fontSize: '18px'  }}
                    onChange={handleChange}
                    required // Make full name field required
                    pattern="[0-9]{10}" // Specify the pattern for 10-digit mobile number
                    title="Please enter a 10-digit mobile number" // Provide a title for validation message
                  />
                </div>
                <div style={{ marginBottom: '15px' }}>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                    style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxSizing: 'border-box', fontSize: '18px'  }}
                    onChange={handleChange}
                    required // Make full name field required
                  />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label htmlFor="selectCourse" style={{ fontSize: '18px', display: 'block' }}>Select course:</label>
                    <select
                      id="selectCourse"
                      name="selectCourse"
                      style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxSizing: 'border-box', fontSize: '18px' }}
                      onChange={handleChange}
                      required // Make full name field required
                    >
                      <option value="">--Select--</option>
                      <option value="8th">8th</option>
                      <option value="9th">9th</option>
                      <option value="10th">10th</option>
                      <option value="11th Science">11th Science</option>
                      <option value="12th Science">12th Science</option>
                      <option value="Polytechnic">Polytechnic</option>
                      <option value="B.E. / B.Tech.">B.E. / B.Tech.</option>
                      <option value="IIT-JEE">IIT-JEE</option>
                      <option value="GATE">GATE</option>
                      <option value="Software Development">Software Development</option>
                      <option value="AutoCAD">AutoCAD</option>
                      <option value="SolidWorks">SolidWorks</option>
                      <option value="CATIA">CATIA</option>
                      <option value="CREO">CREO</option>
                    </select>
                </div>
                <div style={{ marginBottom: '15px' }}>
                <textarea
                    type="text"
                    id="address"
                    name="address"
                    placeholder="Enter your address"
                    style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxSizing: 'border-box', fontSize: '18px' , resize: 'vertical' }}
                    onChange={handleChange}
                    required // Make full name field required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  style={{ width: '100%', padding: '10px', borderRadius: '5px', border: 'none', backgroundColor: '#007bff', color: '#fcf8f8', cursor: 'pointer',  fontSize: '22px' }}
                  disabled={isSubmitting} // Disable the button if form is being submitted
                > {isSubmitting ? 'Submitting...., please wait....' : 'Submit'} {/* Change button text based on submitting state */}
                </button>
                <br/>
              </form>

              {error && (
                <div style={{ backgroundColor: 'red', color: 'white', marginTop: '20px', textAlign: 'center', fontSize: '22px', padding: '10px', borderRadius: '5px' }}>
                Something went wrong. Please Share details on Whats App : 8830222194.
                </div>
              )}

          </div>

        ) : (
              <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <img src={RegistrationSuccess} alt="Thank you" style={{ maxWidth: '80%', height: 'auto', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginBottom: '20px' }} />
                <h2 style={{ color: '#333', fontSize: '36px', fontWeight: 'bold', marginBottom: '20px' }}>Thanks for your Registration!</h2>
                <p style={{ fontSize: '20px', lineHeight: '1.5', marginBottom: '20px', color: '#000', fontFamily: 'Arial, sans-serif' }}>Our administrative team will contact you soon on the given mobile number!</p>
                <NavLink to="/Services" style={{ textDecoration: 'none' }}>
                  <button style={{ padding: '15px 30px', borderRadius: '8px', border: 'none', backgroundColor: '#007bff', color: '#fff', fontSize: '20px', cursor: 'pointer', transition: 'background-color 0.3s' }}>Explore Our Courses</button>
                </NavLink>
              </div>
            )}

        </Col>
      </Row>

        
        
    </Container>
    
    </>
  )
}

export default Home
