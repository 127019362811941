import React from 'react'
import Navbar from './Navbar'
import { Card,  Col, Container, Row } from 'react-bootstrap'


const Contact = () => {
  return (
    <>
    <Navbar></Navbar>
    <Container fluid >
        <Row style={{ margin: '10px' }}>
          <Col sm={12}>
          <Card style={{  border: '1px solid green', background: '#f2f5f3', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Card.Text style={{ textAlign: 'left', border: '1px solid green', background: '#f2f5f3', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              
              <h3 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#0f0f0f', fontStyle: 'italic', fontSize: '20px', lineHeight: '1.5' }}>
              <br/>We prioritize our students and also value their questions.
              <br/><br/>
              Be part of a dynamic learning environment that ignites creativity and cultivates razor-sharp critical thinking skills.
              
              </h3>
              <br/>
              <br/>
              
            </Card.Text>
            <Card.Text style={{ textAlign: 'left', border: '1px solid green', background: '#f2f5f3', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              
              <h2 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#0f0f0f', fontStyle: 'italic', fontSize: '25px', lineHeight: '1.5' }}>
                Contact Us:-
              </h2>

              <h3 style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', color: '#0f0f0f', fontStyle: 'italic', fontSize: '20px', lineHeight: '1.5' }}>
              <br/>Mobile No : 8830222194
              <br/><br/>
              Email Address : shubhjeet101@gmail.com
              <br/><br/>
              Office Address : Hudco Colony, Nara Road, Jaripatka, Nagpur -  440014.
              <br/><br/>
              </h3>
              <br/>
              <br/>
              
            </Card.Text>
            
          </Card>
          </Col>
        </Row>

        
        
    </Container>
    </>
  )
}

export default Contact
